import React from "react"
import { graphql } from "gatsby"
import { MailIcon, MarkGithubIcon } from "@primer/octicons-react"

import Layout from "@components/layout"
import SEO from "@components/seo"
import { css } from "styled-components"
import Link from "@components/link"

interface Props {
  readonly data: PageQueryData
}

const AboutPage: React.FunctionComponent<Props> = ({ data }) => {
  const siteTitle = data.site.siteMetadata.title
  const myEmail = data.site.siteMetadata.author.email
  const myGithub = data.site.siteMetadata.social.github
  const my1 = css`
    background-image:url("/media/about.png");
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    width: 100px;
    height: 100px;
    border-radius: 50%;
    margin: auto 0px auto auto;
    `

  return (
    <Layout title={siteTitle}>
      <SEO title="About" />
      <div>
        <section id="about-section">
          <div className="container-fluid px-0">
            <div className="row d-flex"
              style={{
                height: '100vh',
              }}>
              <div className="col-sm-6 d-flex" >
                <div className="img d-flex align-self-stretch align-items-center mt-auto mr-auto mb-0 ml-auto mr-sm-0 mb-sm-auto" css={my1}></div>
              </div>
              <div className="col-sm-6 d-flex align-items-center">
                <div className="text p-1 p-md-5 mt-0 mr-auto mb-auto ml-auto ml-sm-0 mt-sm-auto">
                  <h2 className="mb-4 fh5co-heading-colored">I"m {data.site.siteMetadata.author.name} a Software Developer</h2>
                  {/* <p><i className="icon-pencil"></i>...</p> */}
                  <nav className="nav">
                    <a className="nav-link" href={`mailto:${myEmail}`}>
                      <MailIcon size={18} />
                    </a>
                    <Link className="nav-link" to={myGithub} target="_blank">
                      <MarkGithubIcon size={18} />
                    </Link>
                  </nav>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </Layout>
  );
}

export default AboutPage

interface PageQueryData {
  site: {
    siteMetadata: {
      title: string
      author: {
        name: string
        summary: string
        email: string
      }
      social: {
        twitter: string
        github: string
      }
    }
  }
}

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
        author {
          name
          summary
          email
        }
        social {
          twitter
          github
        }
      }
    }
  }
`
